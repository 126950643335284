import React, { FC, useEffect, useRef } from "react";
import * as echarts from 'echarts';

interface IGraphProps {
  xAxisValues: any[];
  yAxisValues: any[];
  selection: string;
  dateOfJoining: Date;
  hoursData: any[];
}

const PerformanceGraph: FC<IGraphProps> = ({
  xAxisValues,
  yAxisValues,
  selection,
  dateOfJoining,
  hoursData,
}) => {
  const chartRef = useRef<HTMLDivElement>(null);
  const chartInstance = useRef<echarts.ECharts | null>(null);

  useEffect(() => {
    if (chartRef.current) {
      const chart = echarts.init(chartRef.current);
      chartInstance.current = chart;

      const option: echarts.EChartsOption = {
        tooltip: {
          formatter: (params: any) => {
            if (!params) return '';

            const dataIndex = params?.dataIndex;
            const label = xAxisValues?.[dataIndex];
            const value = yAxisValues?.[dataIndex];

            let monthDate: Date;
            if (selection === 'ytd') {
              // For 'ytd', label is like 'Jan', 'Feb', etc. Get the last date of that month
              const monthIndex = new Date(`${label} 1, ${new Date().getFullYear()}`).getMonth();
              monthDate = new Date(new Date().getFullYear(), monthIndex + 1, 0);
            } else {
              // For 'mtd', label is like 'Dec 3 - Dec 8'. Get the last date of that week
              const startDateStr = label?.split(' - ')[1];
              const yr = new Date().getFullYear();
              const mnth = new Date(`${startDateStr.split(' ')[0]} 1, ${new Date().getFullYear()}`).getMonth();
              const day: number = startDateStr.split(' ')[1];
              monthDate = new Date(yr, mnth, day);
            }

            const isDataAvailable = monthDate >= new Date(dateOfJoining);

            if (isDataAvailable) {
              let billableTooltip = '';
              if (hoursData[dataIndex].billableHours === -1) {
                billableTooltip = 'No data available.';
              } else {
                billableTooltip = `${(hoursData[dataIndex].billableHours / 3600000).toFixed(2)} hours`;
              }

              return `${label}</br>
                Effective hours: ${value} hours</br>
                Productive hours: ${(hoursData[dataIndex].productiveHours / 3600000).toFixed(2)} hours</br>
                Office hours: ${(hoursData[dataIndex].officeHours / 3600000).toFixed(2)} hours</br>
                Billable hours: ${billableTooltip}
              `;
            } else {
              return `${label}: No data available`;
            }

          }
        },
        xAxis: {
          name: selection === 'ytd' ? 'Months' : 'Weeks',
          nameGap: 10,
          data: xAxisValues,
          nameTextStyle: { fontWeight: 'bolder' },
          axisTick: { alignWithLabel: true },
        },
        yAxis: {
          name: 'Hours',
          type: 'value',
          nameLocation: 'end',
          nameGap: 25,
          nameTextStyle: { fontWeight: 'bolder' }
        },
        series: [
          {
            type: 'bar',
            data: yAxisValues,
            itemStyle: {
              color: '#52C0EA',
            },
            barWidth: 30,
          },
          {
            type: 'line',
            data: yAxisValues,
            symbolSize: 10,
            lineStyle: {
              width: 1,
              color: '#072448',
            },
            itemStyle: {
              color: '#072448',
            },
          },
        ],
      };

      chart.setOption(option);

      const resizeChart = () => {
        if (chartInstance.current) {
          chartInstance.current.resize();
        }
      };

      window.addEventListener('resize', resizeChart);

      return () => {
        window.removeEventListener('resize', resizeChart);
        chart.dispose();
      };
    }
  }, [yAxisValues, xAxisValues]);

  return (
    <div ref={chartRef} style={{ width: '100%', height: '400px' }} />
  );
};

export default PerformanceGraph;
