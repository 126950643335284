export const getStartOfDay = (date: Date | string = new Date()) => {
  return (
    Date.parse(new Date(new Date(date).setHours(0, 0, 0, 0)).toString()) -
    new Date().getTimezoneOffset() * 1000 * 60 -
    330 * 60 * 1000
  );
};

export const getEndOfDay = (date: Date | string = new Date()) => {
  return (
    Date.parse(new Date(new Date(date).setHours(23, 59, 59, 999)).toString()) -
    new Date().getTimezoneOffset() * 1000 * 60 -
    330 * 60 * 1000
  );
};

export const formatTimeSpent = (value: number): number => {
  return +(value / (1000 * 60 * 60)).toFixed(2);
};

export const format_HR_MIN_SEC = (seconds: number): string => {
  // const hr = Math.floor(seconds / 3600);
  // const min = Math.floor((seconds % 3600) / 60);
  // return `${hr || 0}hr ${min || 0}min`;
  const hr = Math.floor(seconds / 3600);
  const min = Math.floor((seconds % 3600) / 60);
  const sec = Math.floor(seconds % 60);

  if (seconds <= 0 || (hr === 0 && min === 0 && sec === 0)) {
    return "NA";
  }

  let durationString = "";
  if (hr > 0) {
    durationString += `${hr}hr `;
  }
  if (min > 0) {
    durationString += `${min}min `;
  }
  if (hr === 0 && sec > 0) {
    durationString += `${sec}sec`;
  }
  return durationString;
};

export const DateFormatt = (date: string) => {
  const temp = new Date(date).toDateString().split(" ");
  const hrs = new Date(date).getHours();
  const hr = hrs <= 12 ? hrs : hrs % 12;
  const min = new Date(date).getMinutes();

  return `${temp[2]} ${temp[1]}, ${temp[3]} ${hr > 9 ? hr : `0${hr}`}:${
    min > 9 ? min : `0${min}`
  } ${new Date(date).toLocaleTimeString().split(" ")[1]}`;
};

// timestamp to string
export const customFormat = (date: number, type: "YYYYMMDD" | "DDMMYYYY") => {
  const d = new Date(date);
  let month = "" + (d.getMonth() + 1),
    day = "" + d.getDate();
  const year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  if (type === "YYYYMMDD") {
    return [year, month, day].join("-");
  } else if (type === "DDMMYYYY") {
    return [day, month, year].join("-");
  }
};

// input format = 'hh:mm:ss', returns time difference in seconds
export const getTimestampDifference = (startTime: string, endTime: string) => {
  const [stHours, stMinutes, stSeconds] = startTime.split(":").map(Number);
  const [enHours, enMinutes, enSeconds] = endTime.split(":").map(Number);

  const stDate = new Date(0, 0, 0, stHours, stMinutes, stSeconds);
  const enDate = new Date(0, 0, 0, enHours, enMinutes, enSeconds);

  return Math.abs((enDate.getTime() - stDate.getTime()) / 1000);
};
