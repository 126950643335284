import React, { useEffect, useState } from 'react';
import "./TagsInput.scss";
import { CloseIconSvg } from 'global';

interface TagsInputProps {
  tags: string[];
  selectedTags: (tags: string[]) => void;
  currentInputValue: (value: string) => void;
  clearInput: (clear: () => void) => void;
}

const TagsInput: React.FC<TagsInputProps> = ({
  tags: initialTags,
  selectedTags,
  currentInputValue,
  clearInput,
}) => {
  const [allTags, setAllTags] = useState<string[]>(initialTags);
  const [inputValue, setInputValue] = useState<string>('');

  const removeTags = (indexToRemove: number) => {
    const newTags = allTags.filter((_, index) => index !== indexToRemove);
    setAllTags(newTags);
    selectedTags(newTags);
  };

  const addTags = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && event.currentTarget.value.trim() !== "") {
      const newTags = [...allTags, event.currentTarget.value];
      setAllTags(newTags);
      selectedTags(newTags);
      setInputValue('');
      event.currentTarget.value = "";
    } else if (event.key === "Enter" && event.currentTarget.value.trim() === "") {
      setInputValue('');
      event.currentTarget.value = "";
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInputValue(value);
    currentInputValue(value);
  };

  const clearInputValue = () => {
    setInputValue('');
    currentInputValue('');
  };

  // pass the clear function to parent
  useEffect(() => {
    clearInput(clearInputValue);
  }, [clearInput]);

  useEffect(() => {
    setAllTags(initialTags);
  }, [initialTags]);

  return (
    <div className="tags-input">
      <div className="tags">
        {allTags.map((tag, index) => (
          <li key={index} className="tag">
            <span className='tag-title'>{tag}</span>
            <span className='tag-close-icon'
              onClick={() => removeTags(index)}
            >
              <CloseIconSvg />
            </span>
          </li>
        ))}
      </div>
      <input
        className="input-class"
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        onKeyUp={addTags}
        autoFocus
        placeholder="Press enter to add value"
      />
    </div>
  );
};

export default TagsInput;