import { usersClient } from "../serviceClient";

//get all reports generated by logged in user
//get all columns, params: columns=true
//get all possible values for advanced search, params: advancedSearch=true
//get suggestions on search, params: suggestionSearch=true, key=string, term=string
const getAll = (params?: Record<string, any>) => {
  return usersClient.get<Array<any>>(
    "/report?" +
      (params
        ? Object.entries(params)
            .map(([key, value]) => `${key}=${value}`)
            .join("&")
        : "")
  );
};
const getAllScheduled = () => {
  return usersClient.get<Array<any>>("/report?" + "schedules=true");
};

//get report by id
const getReportById = (id: any) => {
  return usersClient.get<IReport>(`/report/${id}`);
};
const deleteReport = (id: any) => {
  return usersClient.delete<any>(`/report/${id}`);
};

//generate report
const generateReport = (data: any) => {
  return usersClient.post<any>("/report", data);
};
const scheduleReport = (data: any) => {
  return usersClient.post<any>("/report", data);
};

const ReportService = {
  getAll,
  getReportById,
  generateReport,
  scheduleReport,
  getAllScheduled,
  deleteReport,
};

export default ReportService;
