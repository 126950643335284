import React, { FC, useContext, useEffect, useRef, useState } from "react";
import "./UserView.scss";
import { DateRangePicker, Size, ProgressBar, Button, Kind } from "@usitsdasdesign/dds-react";
import { ThemeContext } from "theme/themeContext";
import { Grid, GridItem, Dropdown } from "global";
import CategoryService from "services/CategoryService";
import COLORSlIST, { CAT_COLORS_LIST } from "global/constants/Colors";
import { useDispatch } from "react-redux";
import { SHOWSCREENBLOCKMSG } from "global/store/action";
import { format_HR_MIN_SEC, getEndOfDay, getStartOfDay } from "global/utils/DateUtil";
import { getEvents } from "services/ReportingService";
import UserApps from "../../teamLevel/UserAppsData";
import PerformanceGraph from "./PerformanceGraph";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import UserService from "services/UserService";
import ReportService from "services/ReportService";
import { ColumnsMap } from "home/components/reports/columnsMap";
import { getAllusers } from "services/orgLevelService";

const BASE_CLASS = "userView";
const baseDate = new Date(new Date().setDate(new Date().getDate() - 1));

interface IUserViewProps {
  userData: any;
  legends: any;
}

interface IOption {
  label: string;
  value: string;
}

const UserView: FC<IUserViewProps> = ({
  userData,
  legends,
}) => {
  const dispatch = useDispatch();
  const { themeObjState } = useContext(ThemeContext);

  const DropdownOptions = [
    {
      label: 'Year till date',
      value: 'ytd',
    },
    {
      label: 'Month till date',
      value: 'mtd',
    }
  ];

  const [userAppsData, setUserAppsData] = useState<any>({});
  const [dropdownListValue, setDropdownListValue] = useState<IOption[]>([DropdownOptions[0]]);
  const [xAxisValues, setXAxisValues] = useState<any[]>([]);
  const [yAxisValues, setYAxisValues] = useState<any[]>([]);
  const [hoursData, setHoursData] = useState<any[]>([]);
  const [legendsData, setLegendsData] = useState<
    { label: string; color: string }[]
  >([]);
  const [dateRange, setDateRange] = useState<{
    startDate: Date;
    endDate: Date;
  }>({
    startDate: baseDate,
    endDate: baseDate,
  });

  const hoursExpenditureRef = useRef<HTMLDivElement>(null);
  const performanceAnalysisRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    getHoursData(userData.email + '@deloitte.com');
    setTimeout(() => loadAppsData(userData), 0);
  }, [dateRange]);

  useEffect(() => {
    fetchGraphData(userData.email + '@deloitte.com', dropdownListValue[0].value);
  }, [dropdownListValue]);

  const updateLegendsData = (data: any[]) => {
    const tempLegends = [
      ...legendsData.filter((legend) => legend.label !== "Uncategorized"),
      ...data
        .filter(
          (item: any) =>
            !legendsData.some((legend) => legend.label === item.name)
        )
        .map((item: any, i: number) => ({
          label: item.name,
          color:
            CAT_COLORS_LIST?.[item.name?.toUpperCase()] ||
            COLORSlIST[i % COLORSlIST.length],
        })),
    ];
    setLegendsData([
      ...tempLegends,
      {
        label: "Uncategorized",
        color: COLORSlIST[tempLegends.length % COLORSlIST.length],
      },
    ]);
  };

  /**
   * method to get user apps data
   * @param item user for which app data is required
   * @returns user apps with timespent details
   */
  const loadAppsData = async (item: IUser) => {
    if (!item?.email) return;
    const events = (await getEvents({
      username: item?.email?.split("@")[0],
      startDate: getStartOfDay(dateRange.startDate).toString(),
      endDate: getEndOfDay(dateRange.endDate).toString(),
    })) as any;

    const appsUsedByUser = events.data.data.map((item: any) => item.data.app);

    let newUserData: any = {};
    if (appsUsedByUser.length > 0) {
      const { categoriesData, apps } = await getCategoriesData(item, appsUsedByUser);
      newUserData = {
        ...item,
        categoriesData,
        apps,
      };
    } else {
      newUserData = {
        ...item,
        categoriesData: [],
        apps: undefined,
      };
    }

    const processedUserData = await preprocessing(newUserData, events);

    setUserAppsData((prev: any) => ({
      ...prev,
      ...processedUserData
    }));
  };

  /**
   * method to get user specific category
   * @param userData user for which category data is required
   * @returns user specefic category
   */
  const getCategoriesData = async (userData: IUser, appsUsedByUser: string[]) => {
    try {
      let params: Record<string, string> = {
        functionalArea: btoa(userData?.functionalArea || ""),
        department: btoa(userData?.department || ""),
      };
      if (userData?.subDepartment) {
        params = {
          ...params,
          subDepartment: btoa(userData?.subDepartment || ""),
        };
      }
      const resData: Record<string, any> = await CategoryService.getFilteredCategories(params, { applications: appsUsedByUser });
      const categoriesData = resData.data.data;
      await updateLegendsData(categoriesData);
      const resultData = {
        categoriesData,
        apps: categoriesData
          .map((item: Record<string, any>) => {
            return item.subcategories
              .map((ele: IUser) => ele.apps)
              .flat(1)
              .map((ele: { name: string }) => ({
                appName: ele.name,
                category: item.name,
              }));
          })
          .flat(1),
      };

      return resultData;
    } catch (error) {
      await updateLegendsData([]);
      return { categoriesData: [], apps: undefined };
    }
  };

  /**
   * method to get user events and formatt it accordingly
   * @param email user email
   * @returns user apps with timespent details
   */
  const preprocessing = async (userData: IUser, events: any) => {
    try {
      const cat: Record<string, number> = {};
      events.data.data.forEach((item: any) => {
        if (cat[item?.data?.app]) {
          cat[item.data.app] += Math.floor(item.duration / 1000);
        } else {
          cat[item.data.app] = Math.floor(item.duration / 1000);
        }
      });

      let timespent: any = { Uncategorized: 0 };
      userData?.categoriesData.forEach((item: { name: string }) => {
        timespent = { ...timespent, [item.name]: 0 };
      });

      let totalHrs = 0;
      const data: any = Object.keys(cat).map((key) => {
        const catName =
          userData?.apps?.filter(
            (ele: { appName: string }) => ele.appName === key
          )?.[0]?.category || "Uncategorized";

        totalHrs += cat[key];
        if (timespent[catName]) {
          timespent[catName] += cat[key];
        } else {
          timespent[catName] = cat[key];
        }
        return {
          appName: key,
          duration: cat[key],
          category: catName,
        };
      });

      return {
        ...userData,
        appsData: data,
        category: cat,
        timespent,
        totalHrs,
      };
    } catch (error) {
      console.log(error);
    }
  };

  const getHoursData = async (emailList: string) => {
    try {
      dispatch({ type: SHOWSCREENBLOCKMSG, payload: "Loading..." });

      const params = {
        emailList: emailList,
        startDate: getStartOfDay(dateRange.startDate).toString(),
        endDate: getEndOfDay(dateRange.endDate).toString(),
      };

      await getAllusers(params).then((res) => {
        const resData = res.data.data;

        setUserAppsData((prev: any) => ({
          ...prev,
          billableHours: resData[0].billableHours,
          swipeInOut: resData[0].swipeInOut,
        }));
      });
      dispatch({ type: SHOWSCREENBLOCKMSG, payload: "" });
    } catch (error) {
      dispatch({ type: SHOWSCREENBLOCKMSG, payload: "" });
      console.log("Failed to load data", error);
    }
  };

  const getSingleUserDetails = async (emailId: string) => {
    try {
      const res: any = await UserService.findByEmail(emailId);
      const resData = res?.data?.data[0];
      if (resData?.['doj']) resData['doj'] = resData['doj'].split('T')[0];
      if (resData?.['reportees']) resData['reportees'] = resData['reportees'].length === 0 ? '-' : resData['reportees'];

      return resData;
    } catch (er) {
      console.log('Failed to load user details: ', er);
    }
  };

  const getColumns = async () => {
    try {
      const res: any = await ReportService.getAll({ columns: true });
      const resData: any = res.data?.data;
      const allColumns: any[] = resData.userDetails.map((col: any) => {
        if (!ColumnsMap[`${col}` as keyof typeof ColumnsMap]) {
          return null;
        } else {
          return col;
        }
      }).filter((col: any) => col !== null);

      return allColumns;
    } catch (error) {
      console.log('Failed to load user details: ', error);
    }
  };

  const generatePDF = async (pdfUserDetails: any, columns: any[]) => {
    const doc = new jsPDF();

    const margin = 10;
    const pdfWidth = doc.internal.pageSize.getWidth();
    const availableWidth = pdfWidth - 2 * margin;

    const addContentToPDF = async (ref: React.RefObject<HTMLDivElement>, y: number) => {
      if (ref.current) {
        const canvas = await html2canvas(ref.current);
        const imgData = canvas.toDataURL('image/png');
        const imgProps = doc.getImageProperties(imgData);
        const pdfHeight = (imgProps.height * availableWidth) / imgProps.width;
        const x = margin;

        if (y + pdfHeight > doc.internal.pageSize.getHeight()) {
          doc.addPage();
          y = 10;
        }

        doc.addImage(imgData, 'PNG', x, y, availableWidth, pdfHeight);
        return y + pdfHeight + 5;
      }
      return y;
    };

    const addUserDetailsGridToPDF = (y: number) => {
      const cellPadding = 10;
      const cellHeight = 5;
      const columnWidth = doc.internal.pageSize.getWidth() / 4;

      doc.setFont('helvetica');
      doc.setFontSize(9);
      doc.setDrawColor(208, 208, 206);

      columns.forEach((col: string) => {
        if (y + cellHeight > doc.internal.pageSize.getHeight()) {
          doc.addPage();
          y = 10;
        }

        doc.setFont('helvetica', 'italic');
        doc.text(ColumnsMap[`${col}` as keyof typeof ColumnsMap], cellPadding, y);
        doc.setFont('helvetica', 'normal');
        doc.text(pdfUserDetails[col] ? pdfUserDetails[col].toString() : '-', columnWidth + cellPadding, y);
        doc.line(cellPadding, y + 2, 3 * cellPadding + 2 * columnWidth + 5, y + 2);
        y += cellHeight + 1;
      });

      return y;
    };

    const addGraphGridToPDF = (y: number) => {
      let tempY: number;
      const cellPadding = 10;
      const cellHeight = 5;
      const columnWidth = doc.internal.pageSize.getWidth() / 4;

      doc.setFont('helvetica');
      doc.setFontSize(9);
      doc.setDrawColor(208, 208, 206);

      if (xAxisValues.length > 0 && yAxisValues.length > 0) {
        doc.setFont('helvetica', 'bold');
        doc.text('Time frame', cellPadding, y);
        doc.text('Effective hours', columnWidth + cellPadding, y);
        doc.line(cellPadding, y + 2, 3 * cellPadding + 2 * columnWidth + 5, y + 2);
        y += cellHeight + 1;
        doc.setFont('helvetica', 'normal');
        tempY = y;
      } else {
        return y;
      }

      xAxisValues.forEach((val: string) => {
        if (y + cellHeight > doc.internal.pageSize.getHeight()) {
          doc.addPage();
          y = 10;
        }

        doc.setFont('helvetica', 'italic');
        doc.text(val, cellPadding, y);
        y += cellHeight + 1;
      });

      yAxisValues.forEach((val: string) => {
        if (tempY + cellHeight > doc.internal.pageSize.getHeight()) {
          doc.addPage();
          tempY = 10;
        }

        doc.setFont('helvetica', 'normal');
        doc.text(val, columnWidth + cellPadding, tempY);
        doc.line(cellPadding, tempY + 2, 3 * cellPadding + 2 * columnWidth + 5, tempY + 2);
        tempY += cellHeight + 1;
      });

      return y;
    };

    const addTopAppsToPDF = (y: number) => {
      const cellPadding = 10;
      const cellHeight = 5;
      const columnWidth = doc.internal.pageSize.getWidth() / 3;

      doc.setFont('helvetica');
      doc.setFontSize(9);
      doc.setDrawColor(208, 208, 206);

      doc.setFont('helvetica', 'bold');
      doc.text('App name', margin, y);
      doc.text('Total hours', margin + columnWidth + cellPadding, y);
      doc.text('Category', 2 * columnWidth + cellPadding, y);
      doc.line(cellPadding, y + 2, margin + (2 * columnWidth) + cellPadding + 25, y + 2);
      y += cellHeight + 1;
      doc.setFont('helvetica', 'normal');

      userAppsData.appsData.filter((item: any) => item)
        .sort(
          (a: { duration: number }, b: { duration: number }) => b.duration - a.duration
        ).forEach((item: any) => {
          if (item.duration !== 0) {
            if (y + cellHeight > doc.internal.pageSize.getHeight()) {
              doc.addPage();
              y = 10;
            }

            doc.setFont('helvetica', 'italic');
            const truncatedValue = truncateText(doc, item.appName, columnWidth + cellPadding - 5);
            doc.text(truncatedValue, cellPadding, y);
            doc.setFont('helvetica', 'normal');

            doc.text(format_HR_MIN_SEC(item.duration), margin + columnWidth + cellPadding, y);
            doc.text(item.category, 2 * columnWidth + cellPadding, y);
            doc.line(cellPadding, y + 2, margin + (2 * columnWidth) + cellPadding + 25, y + 2);
            y += cellHeight + 1;
          }
        });

      return y;
    };

    const truncateText = (doc: jsPDF, text: string, maxWidth: number): string => {
      const ellipsis = '...';
      let truncatedText = text;

      const textWidth = doc.getStringUnitWidth(truncatedText) * doc.getFontSize() / doc.internal.scaleFactor;

      if (textWidth > maxWidth) {
        let i = text.length - 1;
        while (i > 0 && doc.getStringUnitWidth(truncatedText + ellipsis) * doc.getFontSize() / doc.internal.scaleFactor > maxWidth) {
          truncatedText = text.substring(0, i);
          i--;
        }
        truncatedText += ellipsis;
      }

      return truncatedText;
    };

    let yPos = 10;

    const heading = 'User report';
    const xOffset = (doc.internal.pageSize.width / 2) - (doc.getStringUnitWidth(heading) * 7 / 2);
    doc.setFont('helvetica', 'bold');
    doc.text('USER REPORT', xOffset, yPos);
    doc.setFont('helvetica', 'normal');
    yPos += 8;

    yPos = addUserDetailsGridToPDF(yPos + 4);
    yPos += 10;

    doc.setFont('helvetica', 'bold');
    doc.setFontSize(11);
    doc.text('Date range', margin, yPos);
    doc.setFont('helvetica', 'normal');
    doc.setFontSize(9);
    yPos += 8;
    doc.text(`Start date: ${dateRange.startDate.toDateString()}`, margin, yPos);
    yPos += 6;
    doc.text(`End date: ${dateRange.endDate.toDateString()}`, margin, yPos);
    yPos += 10;

    yPos = await addContentToPDF(hoursExpenditureRef, yPos);
    yPos = await addContentToPDF(performanceAnalysisRef, yPos);
    doc.addPage();
    yPos = 10;
    yPos = addGraphGridToPDF(yPos);
    yPos += 10;

    doc.setFont('helvetica', 'bold');
    doc.setFontSize(11);
    doc.text('Top apps and websites usage', margin, yPos);
    doc.setFont('helvetica', 'normal');
    doc.setFontSize(9);
    yPos += 8;
    yPos = await addTopAppsToPDF(yPos);

    doc.save(`user__data.pdf`);
  };

  const fetchGraphData = async (email: string, selection: string) => {
    try {
      await UserService.getGraphData(email, selection).then((res: any) => {
        const resData = res.data.data;

        if (resData.length === 0) {
          setXAxisValues([]);
          setYAxisValues([]);
        } else {
          const hoursData = resData.map((item: any) => ({
            ...item,
            billableHours: item.billableHours >= 0 ? item.billableHours * 3600000 : -1,
          }));
          const effectiveHours = hoursData.map((item: any) => {
            const hours = [item.productiveHours, item.officeHours, item.billableHours]
              .filter(value => value >= 0)
              .map(value => (value / 3600000));

            return Math.max(...hours).toFixed(2) || 0;
          });

          setHoursData(hoursData);
          setYAxisValues(effectiveHours);

          if (selection === 'ytd') {
            setXAxisValues(resData.map((item: any) => item.month.slice(0, 3)));
          } else {
            setXAxisValues(resData.map((item: any) =>
              `${new Date(item.startDate).toLocaleString('default', { month: 'short', day: 'numeric' })} - ${new Date(item.endDate).toLocaleString('default', { month: 'short', day: 'numeric' })}`
            ));
          }
        }
      });
    } catch (er) {
      console.log('Failed to load graph data: ', er);
    }
  };

  const getLegends = () => {
    return (
      <div className={`${BASE_CLASS}-legends-container`}>
        {legendsData.map((item) => (
          <div
            key={item.label}
            className={`${BASE_CLASS}-legends-legend dds-body`}
          >
            <div
              className={`${BASE_CLASS}-legends-legend-box`}
              style={{
                backgroundColor: item.color,
              }}
            />
            {item.label === "Uncategorized" ? "Uncategorised" : item.label}
          </div>
        ))}
      </div>
    );
  };

  const getOverallPerformance = () => {
    const effectiveHrs = Math.max(
      userAppsData?.timespent?.['Productive'],
      userAppsData?.billableHours * 3600,
      userAppsData?.swipeInOut / 1000
    );

    let sumOfCategoryHrsExceptProductive = 0;
    const categoriesExceptProductive = legends.filter((leg: any) => leg !== 'Productive');

    categoriesExceptProductive.forEach((item: any) => sumOfCategoryHrsExceptProductive += userAppsData?.timespent?.[item]);

    const total = effectiveHrs + sumOfCategoryHrsExceptProductive;
    return total !== 0 ? (effectiveHrs / total) * 100 : 0;
  };

  const getHoursExpenditureRow = (title: string, value: any, showProgressBar: boolean = false) => {
    return (
      <Grid
        key={title}
        verticalSpacing={"0"}
        className={`${BASE_CLASS}-grid-body-row dds-body`}
      >
        <GridItem xs={5} className={`${BASE_CLASS}-grid-body-row-col-bold ellipsis`}>
          {title}
        </GridItem>
        {!showProgressBar && (
          <GridItem xs={8} className={`${BASE_CLASS}-grid-body-row-col ellipsis`}>
            {format_HR_MIN_SEC(value || 0)}
          </GridItem>
        )}
        {showProgressBar && (
          <GridItem xs={8} className={`${BASE_CLASS}-grid-body-row-col ellipsis`}>
            <ProgressBar
              className="progressBar"
              size={Size.l}
              value={getOverallPerformance()}
            />
          </GridItem>
        )}
      </Grid>
    );
  };

  return (
    <>
      <div className={`${BASE_CLASS}`}>
        <div className={`${BASE_CLASS}-section`}>
          <div className={`${BASE_CLASS}-user-container`}>
            <div
              className={`${BASE_CLASS}-user-image`}
              style={{ backgroundColor: '#63666A' }}
            >
              {userData.name.charAt(0) + userData.name.split(" ").pop().charAt(0)}
            </div>
            <div className={`${BASE_CLASS}-user`}>
              <div className="user-name break-word-white-space">
                {userData.name}
              </div>
              <div className="user-email dds-label-sm-light">
                {userData.email}@deloitte.com
              </div>
              <div className={"user-jobTitle dds-label-sm-light"}>
                {userData.jobTitle}
              </div>
            </div>
          </div>
        </div>

        <div className={`${BASE_CLASS}-section`}>
          <div className={`${BASE_CLASS}-daterange`}>
            <DateRangePicker
              size={Size.l}
              theme={themeObjState}
              maxDate={baseDate}
              labelPosition="external"
              label={"Start and end date"}
              disabledDates={{ after: new Date(), dates: [new Date()] }}
              isManualInput={false}
              format="DD/MM/YYYY"
              icon="calendar__s__stroke"
              customClass="daterange"
              value={[dateRange.startDate, dateRange.endDate]}
              dateRangeSelected={(data: string[] | Date[] | null) => {
                if (data && data.length === 2) {
                  setDateRange({
                    startDate: new Date(data[0]),
                    endDate: new Date(data[1]),
                  });
                }
              }}
            />
            <Button
              size={Size.m}
              kind={Kind.primary}
              theme={themeObjState}
              label="Download all data"
              onClick={async () => {
                const pdfDetails = await getSingleUserDetails(userData.email + '@deloitte.com');
                const allColumns = await getColumns();
                if (allColumns && pdfDetails) {
                  generatePDF(pdfDetails, allColumns);
                }
              }}
            />
          </div>
        </div>

        <div className={`${BASE_CLASS}-section`}>
          <div className={`${BASE_CLASS}-cards`}>
            <div className={`${BASE_CLASS}-cards-card`} ref={hoursExpenditureRef}>
              <div className={`${BASE_CLASS}-cards-card-header dds-h6`}>
                Hours expenditure data
              </div>
              <div className={`${BASE_CLASS}-grid`}>
                {getHoursExpenditureRow('Total hours', userAppsData?.totalHrs)}
                {getHoursExpenditureRow('Overall performance', userAppsData?.totalHrs, true)}
                {legends.map((legend: any) => (
                  getHoursExpenditureRow(legend + ' hours', userAppsData?.timespent ? userAppsData?.timespent?.[legend] : 0)
                ))}
                {getHoursExpenditureRow('Office hours', userAppsData?.swipeInOut / 1000)}
                {getHoursExpenditureRow('Billable hours', userAppsData?.billableHours * 3600)}
              </div>
            </div>
            <div className={`${BASE_CLASS}-cards-card`} ref={performanceAnalysisRef}>
              <div className={`${BASE_CLASS}-cards-card-header dds-h6`}>
                Performance analysis
                <Dropdown
                  className="dropdown"
                  defaultValue={dropdownListValue}
                  showValue={true}
                  onchange={(item: any) => {
                    setDropdownListValue([item]);
                  }}
                  placeholder={"Select date options"}
                  options={DropdownOptions}
                  controlShouldRenderValue={true}
                  isClearable={false}
                  dropdownIndicatorStyle={{ color: "black" }}
                  containerStyle={{ display: "flex", gap: "2px", fontWeight: 400 }}
                  placeholderStyle={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
                />
              </div>
              {(xAxisValues.length > 0 && yAxisValues.length > 0) ? (
                <PerformanceGraph
                  xAxisValues={xAxisValues}
                  yAxisValues={yAxisValues}
                  selection={dropdownListValue[0].value}
                  dateOfJoining={userData.doj}
                  hoursData={hoursData}
                />
              ) : (
                <div className="no-data">
                  <span className="dds-h5">No data is available for the selected dates.</span>
                  <p>Please try different dates.</p>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className={`${BASE_CLASS}-section`}>
          <div className={`${BASE_CLASS}-topApps`}>
            <div className={`${BASE_CLASS}-topApps-header dds-h6`}>
              Top apps and websites usage
            </div>

            {userAppsData && userAppsData.appsData && userAppsData.appsData.length > 0 ? (
              <>
                <UserApps
                  appsData={userAppsData.appsData
                    .filter((item: any) => item)
                    .sort(
                      (a: { duration: number }, b: { duration: number }) =>
                        b.duration - a.duration
                    )}
                  legends={legendsData}
                  categoriesData={userAppsData?.categoriesData}
                  isLoggedUser={false}
                />
                {getLegends()}
              </>
            ) : (
              <div className="no-data">
                <span className="dds-h5">No data is available for the selected dates.</span>
                <p>Please try different dates.</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default UserView;
