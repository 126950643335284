import { categoriesClient } from "serviceClient";
type AppRecord = Record<string, string>;

const getAll = (params?: Record<string, any>) => {
  return categoriesClient.get<Array<AppRecord>>(
    "/category?" +
      (params
        ? Object.entries(params)
            .map(([key, value]) => `${key}=${value}`)
            .join("&")
        : "")
  );
};

const getFilteredCategories = (params: Record<string, any>, data: any) => {
  return categoriesClient.post<Array<AppRecord>>(
    "/category/categorization?" +
      (params
        ? Object.entries(params)
            .map(([key, value]) => `${key}=${value}`)
            .join("&")
        : "")
  , data);
};

const get = (id: any) => {
  return categoriesClient.get<ICategory>(`/category/${id}`);
};

const create = (data: ICategory) => {
  return categoriesClient.post<ICategory>("/category", data);
};

const update = (id: any, data: ICategory, params?: Record<string, any>) => {
  return categoriesClient.put<any>(
    `/category/${id}?` +
      (params
        ? Object.entries(params)
            .map(([key, value]) => `${key}=${value}`)
            .join("&")
        : ""),
    data
  );
};

const remove = (id: any) => {
  return categoriesClient.delete<any>(`/category/${id}`);
};

const changeReq = (data: Record<string, any>) => {
  return categoriesClient.post<any>(`/changeEvents`, data);
};
const getAllChangeRequest = (params?: Record<string, any>) => {
  return categoriesClient.get<any>(`/changeEvents`, { params });
};
const updateChangeReq = (id: string, data: Record<string, string>) => {
  return categoriesClient.put<any>(`/changeEvents/${id}`, data);
};

const CategoryService = {
  getAll,
  getAllChangeRequest,
  updateChangeReq,
  get,
  create,
  update,
  remove,
  changeReq,
  getFilteredCategories,
};

export default CategoryService;
