import React from "react";
import { CSSProperties, FC, useEffect, useState } from "react";
import "./Chips.scss";
import { CloseIconTeal } from "global";

interface ChipsInterface {
  initialChips: string[];
  maxChips?: string | number;
  maxLength?: string | number;
  placeholder?: string;
  style?: CSSProperties;
  mainClassName?: string;
  addLabel?: boolean;
  labelText?: string;
  inputBoxClassName?: string;
  setChipsInParent?: any;
  emailCheck?: boolean;
}

export const Chips: FC<ChipsInterface> = ({
  initialChips,
  maxChips,
  // maxLength,
  placeholder,
  style = {},
  mainClassName = "",
  addLabel,
  labelText,
  inputBoxClassName,
  setChipsInParent = () => {},
  emailCheck,
}) => {
  const [chips, setChips] = useState<any>(initialChips);

  useEffect(() => {
    setChips(initialChips);
  }, [initialChips]);

  const KEY = { backspace: 8, tab: 9, enter: 13 };
  const handleKeyDown = (event: any) => {
  const keyPressed = event.which;

    if (
      keyPressed === KEY.enter ||
      (keyPressed === KEY.tab && event.target.value)
    ) {
      event.preventDefault();
      updateChips(event);
    } else if (keyPressed === KEY.backspace) {
      if (!event.target.value && chips.length) {
        deleteChip(chips[chips.length - 1]);
      }
    }
  };

  const updateChips = (event: any) => {
    if (!maxChips || chips.length < maxChips) {
      const value = event.target.value;

      if (!value) return;

      const chip = value.trim().toLowerCase();

      if (chip && chips.indexOf(chip) < 0) {
        setChips([...chips, chip]);
        setChipsInParent([...chips, chip]);
      }
    }

    event.target.value = "";
  };

  const deleteChip = (chipToDelete: string) => {
    const remainingChips = chips.filter((chip: any) => {
      return chip !== chipToDelete;
    });
    setChips(remainingChips);
    setChipsInParent(remainingChips);
  };
  const chipsElements = chips.map((chip: any, index: any) => {
    return (
      <div
        style={
          emailCheck && !chip.includes("deloitte.com")
            ? { border: "1px solid red" }
            : {}
        }
        className="chip"
        key={index}
      >
        <div
          style={
            emailCheck && !chip.includes("deloitte.com") ? { color: "red" } : {}
          }
          className="chip-value dds-link-sm"
        >
          {chip}
        </div>
        <button
          type="button"
          className="chip-delete-button"
          onClick={deleteChip.bind(null, chip)}
        >
          <img
            className={
              emailCheck && !chip.includes("deloitte.com") ? "danger-icon" : ""
            }
            src={CloseIconTeal}
            alt="remove"
          />
        </button>
      </div>
    );
  });

  return (
    <div className={`chips-container ${mainClassName}`}>
      {addLabel ? (
        <label className="--label-box dds-label">{labelText}</label>
      ) : null}
      <div style={style} className={`--chipsbox-main ${inputBoxClassName}`}>
        <div className="--chips" onClick={() => {}}>
          {chipsElements}
          <input
            type="text"
            className="--chips-input"
            placeholder={placeholder}
            onKeyDown={handleKeyDown}
            onKeyUp={() => {}}
          />
        </div>
      </div>
    </div>
  );
};
