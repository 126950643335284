import {
  SearchBox,
  Pagination,
  Grid,
  GridItem,
  // DownloadIcon,
  SortIcon,
  noCategoriesIcon,
  // ViewReportIcon,
  // EditReportIcon,
  Tooltip,
  NoDataFoundIcon,
  // DeleteIcon,
  DeleteClauseIcon,
  PopUpModel,
} from "global";
import React, { useEffect, useState } from "react";
import "./ScheduledReports.scss";
import { CALL_NOTIFY, SHOWSCREENBLOCKMSG } from "global/store/action";
import ReportService from "services/ReportService";
import { useDispatch } from "react-redux";
import { DateFormatt } from "global/utils/DateUtil";
import { PositionState, InvokeType } from "global/components/tooltip/typings";
interface ScheduledReportsProps {
  reportData?: any;
}

const ScheduledReports: React.FC<ScheduledReportsProps> = () => {
  const BASE_CLASS = "scheduledReports";
  const itemsPerPage = 10;
  const [reportsData, setReportsData] = useState<any>([]);
  const dispatch = useDispatch();
  const [isSortAsc, setSort] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [deleteRep, setDeleteRep] = useState(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<string>("");
  const [reportName, setReportName] = useState<string>("");
  useEffect(() => {
    fetchReports();
  }, [deleteRep]);

  const fetchReports = async () => {
    try {
      dispatch({ type: SHOWSCREENBLOCKMSG, payload: "Loading..." });
      await ReportService.getAllScheduled().then((res: any) => {
        setReportsData(res.data.data);
        const newRes = res.data.data;
        newRes.sort((a: any, b: any) => {
          const dateA: any = new Date(a.created);
          const dateB: any = new Date(b.created);
          return dateB - dateA; // Sort in descending order (latest first)
        });
      });

      dispatch({ type: SHOWSCREENBLOCKMSG, payload: "" });
    } catch (error) {
      dispatch({ type: SHOWSCREENBLOCKMSG, payload: "" });
      dispatch({
        type: CALL_NOTIFY,
        payload: {
          type: "ERROR",
          msg: `Error while fetching reports. Please try again later.`,
          timeout: 3000,
        },
      });
    }
  };
  const deleteReport = async (id: any) => {
    try {
      dispatch({ type: SHOWSCREENBLOCKMSG, payload: "Loading..." });
      await ReportService.deleteReport(id).then((res: any) => {
        dispatch({
          type: CALL_NOTIFY,
          payload: {
            type: "SUCCESS",
            msg: res?.data?.message || `Successfully deleted report`,
            timeout: 3000,
          },
        });
        setDeleteRep(!deleteRep);
      });
    } catch (error) {
      dispatch({ type: SHOWSCREENBLOCKMSG, payload: "" });
      dispatch({
        type: CALL_NOTIFY,
        payload: {
          type: "ERROR",
          msg: `Error while deleting report`,
          timeout: 3000,
        },
      });
    }
  };

  return (
    <>
      <div className={`${BASE_CLASS}`}>
        <div className={`${BASE_CLASS}-header dds-h5`}>Scheduled reports</div>

        <div className={`${BASE_CLASS}-searchContainer`}>
          <SearchBox
            className={`${BASE_CLASS}-searchbox`}
            placeholder="Search here"
            style={{ width: "350px" }}
            value={searchText}
            onChange={(val: string) => {
              setSearchText(val);
            }}
            onClear={() => {
              setSearchText("");
            }}
          />
        </div>
        {reportsData.length === 0 ? (
          <div className={`${BASE_CLASS}-nosearchdata`}>
            <img src={noCategoriesIcon} alt="No reports" />
            <p>No reports scheduled.</p>
          </div>
        ) : (
          <>
            <div className={`${BASE_CLASS}-grid`}>
              {reportsData.filter((report: any) =>
                report.name
                  .toLocaleLowerCase()
                  .includes(searchText.trim().toLocaleLowerCase())
              ).length > 0 ? (
                <>
                  <Grid
                    verticalSpacing={"0"}
                    columns={24}
                    className={`${BASE_CLASS}-grid-title-row dds-body`}
                  >
                    <GridItem
                      xs={10}
                      className={`${BASE_CLASS}-grid-title-row-col `}
                    >
                      Report name
                    </GridItem>
                    <GridItem
                      xs={10}
                      className={`${BASE_CLASS}-grid-title-row-col `}
                    >
                      Date of Creation
                      <img
                        onClick={() => setSort(!isSortAsc)}
                        src={SortIcon}
                        alt="sortIcon"
                        className={`cursor ${BASE_CLASS}-grid-title-row-col-icon-sort-${
                          isSortAsc ? "ASC" : "DESC"
                        }`}
                      />
                    </GridItem>
                    <GridItem
                      xs={10}
                      className={`${BASE_CLASS}-grid-title-row-col `}
                    >
                      Scheduled For
                      <img
                        onClick={() => setSort(!isSortAsc)}
                        src={SortIcon}
                        alt="sortIcon"
                        className={`cursor ${BASE_CLASS}-grid-title-row-col-icon-sort-${
                          isSortAsc ? "ASC" : "DESC"
                        }`}
                      />
                    </GridItem>
                    <GridItem
                      xs={4}
                      className={`${BASE_CLASS}-grid-title-row-col `}
                    >
                      Action
                    </GridItem>
                  </Grid>

                  {(isSortAsc ? reportsData?.slice().reverse() : reportsData)
                    .filter((report: any) =>
                      report.name
                        .toLocaleLowerCase()
                        .includes(searchText.trim().toLocaleLowerCase())
                    )
                    .slice(
                      (currentPage - 1) * itemsPerPage,
                      currentPage * itemsPerPage
                    )
                    .map((item: any, i: number) => (
                      <Grid
                        key={i}
                        verticalSpacing={"0"}
                        className={`${BASE_CLASS}-grid-body-row dds-body`}
                      >
                        <GridItem
                          xs={10}
                          className={`${BASE_CLASS}-grid-body-row-col ellipsis`}
                        >
                          {item.name + ".xlsx"}
                        </GridItem>
                        <GridItem
                          xs={10}
                          className={`${BASE_CLASS}-grid-body-row-col ellipsis`}
                        >
                          {DateFormatt(item.created)}
                        </GridItem>
                        <GridItem
                          xs={10}
                          className={`${BASE_CLASS}-grid-body-row-col`}
                        >
                          <div style={{ display: "flex" }}>
                            {JSON.parse(item.scheduledFor)[0]}

                            {JSON.parse(item.scheduledFor).length > 1 && (
                              <Tooltip
                                toolTipText={`${JSON.parse(item.scheduledFor)
                                  .slice(1)
                                  .join("\r\n")}`}
                                tooltipPosition={PositionState.bottom}
                                tooltipInvokeType={InvokeType.hover}
                                tooltipWidth={"max-content"}
                              >
                                <div className="schedule-chips cursor">
                                  +{JSON.parse(item.scheduledFor).length - 1}
                                </div>
                              </Tooltip>
                            )}
                          </div>
                        </GridItem>
                        <GridItem
                          xs={4}
                          className={`${BASE_CLASS}-grid-body-row-col`}
                        >
                          <div
                            className={`${BASE_CLASS}-grid-body-row-col-icon`}
                          >
                            <Tooltip
                              toolTipText={"Delete"}
                              tooltipPosition={PositionState.bottom}
                              tooltipInvokeType={InvokeType.hover}
                              tooltipWidth={"max-content"}
                            >
                              <img
                                src={DeleteClauseIcon}
                                alt="delete"
                                className={`cursor ${BASE_CLASS}-grid-body-row-col`}
                                onClick={() => {
                                  //   deleteReport(item.id);
                                  setDeleteModal(true);
                                  setDeleteId(item.id);
                                  setReportName(item.name);
                                  //   setClickedReportId(item.id);
                                  //   setShowViewModal(true);
                                }}
                              />
                            </Tooltip>
                          </div>
                        </GridItem>
                      </Grid>
                    ))}
                  {deleteModal && (
                    <PopUpModel
                      saveTitle="Delete"
                      saveRemoveStyle={true}
                      onSaveClick={() => {
                        deleteReport(deleteId);
                        setDeleteModal(false);
                      }}
                      onClickClose={() => {
                        setDeleteModal(false);
                        setDeleteId("");
                      }}
                      Title="Confirm delete"
                    >
                      <div>
                        Are you sure you want to delete the scheduled report{" "}
                        {reportName}?
                      </div>
                    </PopUpModel>
                  )}
                  <div className={`${BASE_CLASS}-grid-pagination`}>
                    <Pagination
                      currentPage={currentPage || 1}
                      lastPage={Math.ceil(
                        reportsData?.filter((report: any) =>
                          report.name
                            .toLocaleLowerCase()
                            .includes(searchText.trim().toLocaleLowerCase())
                        ).length / itemsPerPage
                      )}
                      setCurrentPage={(item) => setCurrentPage(item)}
                    />
                  </div>
                </>
              ) : (
                <div className={`${BASE_CLASS}-nosearchdata`}>
                  <img src={NoDataFoundIcon} alt="No reports" />
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};
export default ScheduledReports;
